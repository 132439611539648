import { getTags } from '../api/index';

const useTagList = async(type) =>{
    const response = await getTags({type});
    let tags = []
    if(response.status !== "fail"){
        tags = response?.data?.tags?.map(({id, name}) => ({
            value: id, label: name
        }));
    }
    return {tags}
}

export default useTagList;